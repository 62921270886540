import { createDarkTheme } from "baseui"

const primitives = {
  // Primary Palette
  primaryA: "#E2E2E2",
  primaryB: "#102A43",
  primary: "#f8f9fb",
  primary50: "#f8fafc",
  primary100: "#F0F4F8",
  primary200: "#D9E2EC",
  primary300: "#9FB3C8",
  primary400: "#829AB1",
  primary500: "#5b758f",
  primary600: "#335475",
  primary700: "#1f3347",
  // Give a tint to the dark mono colors
  mono300: "#4c5667",
  mono400: "#3d4552",
  mono500: "#2e3238",
  mono600: "#292d32",
  mono700: "#22262a",
  mono800: "#232324",
  mono900: "#1e1e1f",
  // mono1000: "black", #default
}

// mono200 / gray400 (https://github.com/uber/baseweb/blob/master/src/tokens/colors.js)
const mono200 = "#AFAFAF"

const overrides = {
  colors: {
    // Ref: https://github.com/uber/baseweb/blob/master/src/themes/light-theme/color-semantic-tokens.js
    // Background
    backgroundPrimary: primitives.mono900,
    backgroundSecondary: primitives.mono800,
    backgroundTertiary: primitives.mono700,
    backgroundInversePrimary: primitives.primaryA,
    backgroundInverseSecondary: primitives.primary300,

    // Content
    contentPrimary: primitives.primaryA,
    contentSecondary: primitives.primary300,
    contentTertiary: primitives.primary400,
    contentInversePrimary: primitives.primaryB,
    contentInverseSecondary: primitives.primary600,
    contentInverseTertiary: primitives.primary500,

    // Change all non-disabled text with mono300 (gray500) to mono200 (gray400) to make the contrast higher
    // https://github.com/uber/baseweb/blob/master/src/themes/dark-theme/color-component-tokens.js
    modalCloseColor: mono200,
    tableFilterHeading: mono200,
    toggleFill: mono200,
    tickBorder: mono200,
    sliderHandleFill: mono200,
    sliderHandleFillHover: mono200,
    sliderHandleFillActive: mono200,
    sliderHandleInnerFill: mono200,
    inputPlaceholder: mono200,
    menuFontDefault: mono200,
    tabColor: mono200,

    // Override tooltip
    tooltipBackground: primitives.mono600,
    tooltipText: primitives.primaryA,
  },

  typography: {
    DisplayLarge: {
      fontWeight: "700",
    },
    DisplayMedium: {
      fontWeight: "700",
    },
    DisplaySmall: {
      fontWeight: "700",
    },
    DisplayXSmall: {
      fontWeight: "700",
    },
    HeadingXXLarge: {
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "700",
    },
    HeadingXLarge: {
      fontSize: "32px",
      lineHeight: "40px",
      fontWeight: "700",
    },
    HeadingLarge: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: "700",
    },
    HeadingMedium: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "600",
    },
    HeadingSmall: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
    },
    HeadingXSmall: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
    },
    LabelLarge: {
      fontSize: "22px",
      lineHeight: "24px",
      fontWeight: "600",
    },
    LabelMedium: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "600",
    },
    LabelSmall: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
    },
    LabelXSmall: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: "600",
    },
    ParagraphLarge: {
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: "400",
    },
    ParagraphNormal: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "400",
    },
  },
}

// https://github.com/uber/baseweb/blob/4efe7fabbb06c5301f7faabbe56d833d9bc51a91/documentation-site/pages/_app.js
const breakpoints = {
  small: 670,
  medium: 920,
  large: 1280,
}

const ResponsiveTheme = Object.keys(breakpoints).reduce(
  (acc, key) => {
    acc.mediaQuery[key] = `@media screen and (min-width: ${breakpoints[key]}px)`
    return acc
  },
  {
    breakpoints,
    mediaQuery: {},
  }
)

const DarkTheme = createDarkTheme(primitives, overrides)
const ResponsiveDarkTheme = { ...DarkTheme, ...ResponsiveTheme }

export default ResponsiveDarkTheme
