import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import { SubTitle, Title, ItemDescription } from "../../components/typography"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const Recommendation = ({ children }) => {
  return (
    <Block
      marginTop={["50px", "50px", "100px"]}
      paddingBottom="50px"
      id="related-content"
    >
      <Container>
        <Title>Related Content</Title>
        <Block marginLeft={[0, "5%", "20%", 0]} marginRight={[0, "5%", 0, 0]}>
          <Fade up>
            <FlushedGrid>
              {children &&
                React.Children.map(children, child => (
                  <Cell span={6}>{child}</Cell>
                ))}
            </FlushedGrid>
          </Fade>
        </Block>
      </Container>
    </Block>
  )
}

export default Recommendation
