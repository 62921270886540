import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { LabelMedium } from "baseui/typography"
import { HiChevronDown } from "react-icons/hi"
import Drawer from "../../components/Drawer"
// import Button from "../../components/Button"
import Logo from "../../components/UIElements/Logo"
import HamburgMenu from "../../components/HamburgMenu"
import Container from "../../components/UI/Container"
import { DrawerContext } from "../../contexts/DrawerContext"
import Dropdown from "../../components/MenuDropDown"
import LogoWhite from "../../images/cylynx-logo-white.svg"
import LogoColor from "../../images/cylynx-logo.svg"

const MainMenu = ({ menu }) => {
  const { path, label, DROPDOWN } = menu
  const textlink = path ? (
    <Link id={`nav-${label.replace(/\s+/g, "-").toLowerCase()}`} to={path}>
      <LabelMedium>{label}</LabelMedium>
    </Link>
  ) : (
    <Block display="flex" alignItems="flex-end" gridGap="6px">
      {label}
      <HiChevronDown />
    </Block>
  )
  return (
    <>
      {DROPDOWN && DROPDOWN.length > 0 ? (
        <Dropdown content={DROPDOWN}>{textlink}</Dropdown>
      ) : (
        textlink
      )}
    </>
  )
}

const MobileMenu = ({ menu }) => {
  const { path, label, DROPDOWN } = menu
  const textlink = path ? (
    <Link id={`nav-${label.replace(/\s+/g, "-").toLowerCase()}`} to={path}>
      <Block color="#000">{label}</Block>
    </Link>
  ) : (
    <Block display="flex" alignItems="center" gridGap="6px" color="#788796">
      {label}
      <HiChevronDown color="#788796" />
    </Block>
  )
  return (
    <>
      {textlink}
      {DROPDOWN &&
        DROPDOWN.length > 0 &&
        DROPDOWN.map(item => (
          <Block
            marginLeft="16px"
            marginTop="8px"
            marginBottom="8px"
            key={item.title}
          >
            <Link to={item.path}>
              <Block color="#000">{item.title}</Block>
            </Link>
          </Block>
        ))}
    </>
  )
}

const Navbar = ({ isTop, isSimple }) => {
  const { state, dispatch } = useContext(DrawerContext)

  let bgcolor = "white"
  if (isSimple && isTop) {
    bgcolor = "#334E68"
  } else if (isTop) {
    bgcolor = "transparent"
  }

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    })
  }

  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        MENU_ITEMS {
          label
          path
          DROPDOWN {
            title
            description
            path
          }
        }
      }
    }
  `)

  return (
    <Block backgroundColor={bgcolor} className="saas_navbar">
      <Container>
        <Block
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Logo
            to="/"
            logoSrc={LogoWhite}
            title="Cylynx"
            logoStyle={{
              maxWidth: ["120px", "130px"],
            }}
            className="main-logo"
          />
          <Logo
            to="/"
            logoSrc={LogoColor}
            title="Cylynx"
            logoStyle={{
              maxWidth: ["120px", "130px"],
            }}
            className="logo-alt"
          />
          {/* Main Menu */}
          <Block
            display={["none", "none", "flex", "flex"]}
            alignItems="center"
            className="scrollspy__menu main_menu"
          >
            {Data.cryptoJson.MENU_ITEMS.map((menu, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`menu-item-${index}`}>
                {menu.staticLink ? (
                  <a href={menu.path}>{menu.label}</a>
                ) : (
                  <MainMenu menu={menu} />
                )}
              </li>
            ))}
            <Link className="navbar_button" to="/contact-us">
              <Button id="request-demo">Request Demo</Button>
            </Link>
          </Block>
          {/* Mobile Menu */}
          <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu barColor="#fff" />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <Block
              display={["block", "block", "none", "none"]}
              alignItems="center"
              className="scrollspy__menu mobile_menu"
            >
              {Data.cryptoJson.MENU_ITEMS.map((menu, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`menu-item-${index}`}>
                  {menu.staticLink ? (
                    <a href={menu.path}>{menu.label}</a>
                  ) : (
                    <MobileMenu menu={menu} />
                  )}
                </li>
              ))}
              <Link className="navbar_drawer_button" to="/contact-us">
                <Button>Request Demo</Button>
              </Link>
            </Block>
          </Drawer>
        </Block>
      </Container>
    </Block>
  )
}

export default Navbar
