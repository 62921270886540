import React from "react"
import PropTypes from "prop-types"
import { MDXProvider } from "@mdx-js/react"
import Sticky from "react-stickynode"
import { BaseProvider, useStyletron } from "baseui"
import ImgixClient from "imgix-core-js"

import Footer from "../containers/Footer"
import { DrawerProvider } from "../contexts/DrawerContext"
import Navbar from "../containers/Navbar"
import { NewsletterBlog } from "../containers/Newsletter"
import Recommendation from "../containers/Recommendation"
import { RecommendationCard } from "./BlogComponents"
import { CsvDataTable } from "./DataTable"
import { CustomLightTheme } from "../theme"
import { GlobalStyle, ContentWrapper } from "./app.style"
import CookieConsent from "./CookieConsent/index"

// Transforming mdx img to generate responsive srcset
const ImgixDomain = "cylynx.imgix.net"
const client = new ImgixClient({
  domain: ImgixDomain,
})

const Img = ({ src, ...rest }) => {
  let responsiveImage
  if (src.includes(ImgixDomain) && !src.includes("gif")) {
    const srcset = client.buildSrcSet(
      src.replace(`https://${ImgixDomain}`, ""),
      {},
      { maxWidth: 780 }
    )
    responsiveImage = (
      <img srcSet={srcset} src={`${src}?auto=format,compress`} {...rest} />
    )
  } else {
    responsiveImage = <img src={src} {...rest} />
  }

  return <>{responsiveImage}</>
}

const StyledA = ({ href, children, ...rest }) => {
  const [css] = useStyletron()
  return (
    <a
      href={href}
      target={href.includes("cylynx.io") ? "_self" : "_blank"}
      className={css({
        textDecoration: "none",
        color: "#1992D4",
        ":hover": {
          color: "#1992D4",
        },
        ":visited": {
          color: "#1992D4",
        },
      })}
      {...rest}
    >
      {children}
    </a>
  )
}

// Add component shortcode to be passed to MDX
const components = {
  NewsletterBlog,
  CsvDataTable,
  Recommendation,
  RecommendationCard,
  img: Img,
  a: StyledA,
}

// Set isSimple to use simple headerNav bar
const Layout = ({ isSimple, children }) => {
  return (
    <>
      <BaseProvider theme={CustomLightTheme}>
        <MDXProvider components={components}>
          <GlobalStyle />
          <ContentWrapper>
            <Sticky top={0} innerZ={2} activeClass="sticky-nav-active">
              {status => (
                <DrawerProvider>
                  <Navbar
                    isTop={status.status === Sticky.STATUS_ORIGINAL}
                    isSimple={isSimple}
                  />
                </DrawerProvider>
              )}
            </Sticky>
            {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
            {children}
            <CookieConsent />
            <Footer />
          </ContentWrapper>
        </MDXProvider>
      </BaseProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isSimple: PropTypes.bool,
}

Layout.defaultProps = {
  isSimple: false,
}

export default Layout
