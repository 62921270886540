import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { useForm } from "react-hook-form"
import { Button } from "baseui/button"
import { StatefulInput } from "baseui/input"
import { Block } from "baseui/block"
import Box from "../../components/Box"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import Container from "../../components/UI/Container"
import { Description } from "../../components/typography"
import { logNewsletterSignUp } from "../../utils/analytics"

import {
  NewsletterWrapper,
  NewsletterWrapperBlog,
  ContactFormWrapper,
} from "./newsletter.style"

export const Newsletter = ({
  sectionWrapper,
  textArea,
  title,
  description,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <NewsletterWrapper id="newsletter">
          <Box {...textArea}>
            <Heading content="Get the latest updates" {...title} />
            <Text
              content="Subscribe to our newsletter to receive regular updates"
              {...description}
            />
          </Box>
          <Box>
            <NewsletterBasic width="470px" />
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  )
}

export const NewsletterBlog = ({ title, description }) => {
  return (
    <NewsletterWrapperBlog>
      <Heading content="Enjoying the content?" {...title} />
      <Text
        content="Subscribe to get updates when a new post is published"
        {...description}
      />
      <NewsletterBasic width="100%" />
    </NewsletterWrapperBlog>
  )
}

export const NewsletterBasic = props => {
  const [isSubmit, setIsSubmit] = useState(false)
  const { handleSubmit, register } = useForm()
  const onSubmit = data => {
    axios.post("https://usebasin.com/f/8dedadb2fe38.json", data).then(() => {
      setIsSubmit(true)
      logNewsletterSignUp()
    })
  }

  return (
    <Block width="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContactFormWrapper width={props.width}>
          <StatefulInput
            type="email"
            name="email"
            required
            placeholder="Email address"
            inputRef={register}
          />
          <div style={{ position: "absolute", left: "-5000px" }}>
            <input
              type="checkbox"
              name="strong_pearl_houndstooth_wookie"
              value="1"
              tabIndex="-1"
              autoComplete="no"
            />
          </div>
          <Button id="newsletter-subscribe">Subscribe</Button>
        </ContactFormWrapper>
        {isSubmit && <Description>Thank you for subscribing!</Description>}
      </form>
    </Block>
  )
}

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
}

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ["40px", "40px", "40px", "0", "0"],
    pr: ["0", "0", "0", "80px", "100px"],
  },
  title: {
    fontSize: ["24px", "26px", "30px", "30px", "30px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    textAlign: ["center", "center", "center", "left", "left"],
    letterSpacing: "-0.010em",
    mb: "16px",
    maxWidth: ["100%", "100%", "100%", "100%", "415px"],
  },
  description: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: 0,
    textAlign: ["center", "center", "center", "left", "left"],
  },
}
