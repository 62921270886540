import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { useStyletron } from "baseui"
import { Block } from "baseui/block"
import Link from "../../components/Link"
import Box from "../../components/Box"
import Heading from "../../components/Heading"
import Logo from "../../components/UIElements/Logo"
import Container from "../../components/UI/Container"
import FooterWrapper from "./footer.style"

import LogoImage from "../../images/cylynx-logo.svg"

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
}) => {
  const [css] = useStyletron()
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        FOOTER_DATA {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper id="footerSection">
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              to="/"
              logoSrc={LogoImage}
              title="Hosting"
              logoStyle={logoStyle}
            />
            <Block marginTop="8px" marginBottom="12px">
              <Link
                href="mailto:hello@cylynx.io"
                className={css({ color: "rgba(52,61,72,0.8)" })}
              >
                hello@cylynx.io
              </Link>
            </Block>
            <Block
              marginTop="8px"
              marginBottom="12px"
              color="rgba(52,61,72,0.8)"
              className={css({ fontSize: "14px" })}
            >
              {`${new Date().getFullYear()} © Cylynx Pte Ltd`}
            </Block>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.cryptoJson.FOOTER_DATA.map(widget => (
              <Box
                className="col"
                {...col}
                key={widget.title}
              >
                <Heading content={widget.title} {...titleStyle} />
                <ul>
                  {widget.menuItems.map(item => (
                    <li key={item.text}>
                      <Link
                        to={item.url}
                        className={css({
                          color: "rgba(52,61,72,0.8)",
                          fontSize: "14px",
                          lineHeight: "24px",
                          ":visited": {
                            color: "rgba(52,61,72,0.8)",
                          },
                        })}
                      >
                        {item.text}
                      </Link>
                      </li>
                  ))}
                </ul>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  )
}

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
}

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  // Footer col one style
  colOne: {
    width: [1, "35%", "35%", "23%"],
    mt: [0, "13px"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "65%", "65%", "77%"],
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  // widget title default style
  titleStyle: {
    color: "#343d48",
    fontSize: "16px",
    fontWeight: "700",
    mb: "30px",
  },
  // Default logo size
  logoStyle: {
    width: "130px",
    mb: "20px",
  },
  // widget text default style
  textStyle: {
    color: "#0f2137",
    fontSize: "14px",
    mb: "10px",
  },
}

export default Footer
