import React from "react"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import Img from "gatsby-image"
import { Avatar } from "baseui/avatar"
import { Block } from "baseui/block"
import { ItemTitle } from "../typography"
import { PostCardSection } from "./blog.style"

export const Author = ({ children }) => (
  <p
    style={{
      marginTop: "-5px",
      marginLeft: "12px",
      marginBottom: "0px",
      fontSize: "14px",
      fontWeight: 700,
    }}
  >
    {children}
  </p>
)

export const DateInfo = ({ children }) => (
  <p
    style={{
      marginTop: "-10px",
      marginLeft: "12px",
      fontSize: "14px",
      fontWeight: 400,
      color: "#486581",
    }}
  >
    {children}
  </p>
)

export const TagList = ({ tags }) => (
  <Block display="flex" flexDirection="row" marginBottom={0} marginTop="16px">
    {tags.map(tag => (
      <Link
        key={tag}
        to={`/tags/${kebabCase(tag)}`}
        style={{ paddingRight: "10px", fontWeight: 600, color: "#1992D4" }}
      >
        {tag}
      </Link>
    ))}
  </Block>
)

export const RecommendationCard = ({ imgSrc, slug, title, description }) => {
  return (
    <PostCardSection>
      <article className={`post-content post-card post`}>
        <Link className="post-card-image-link" to={slug}>
          {imgSrc ? (
            <img
              src={imgSrc}
              alt={`${title} image`}
              className="post-card-image"
            />
          ) : null}
        </Link>
        <div className="post-card-content">
          <div className="post-card-content-link">
            <Link to={slug}>
              <ItemTitle marginTop="6px">{title}</ItemTitle>
              <div className="post-card-description">
                <p>{description}</p>
              </div>
            </Link>
          </div>
        </div>
      </article>
    </PostCardSection>
  )
}

export const PostCard = ({ post, num, isHome }) => {
  const large =
    isHome && ((post.imgixImage && num % 5 === 0) || post.frontmatter.featured)
      ? `post-card-large`
      : ``

  return (
    <PostCardSection>
      <article className={`post-content post-card post ${large}`}>
        <Link className="post-card-image-link" to={post.fields.slug}>
          {post.frontmatter.hero_image ? (
            <Img
              fluid={{ ...post.imgixImage.fluid }}
              alt={post.frontmatter.title}
              className="post-card-image"
            />
          ) : null}
        </Link>

        <div className="post-card-content">
          <div className="post-card-content-link" to={post.fields.slug}>
            <TagList tags={post.frontmatter.tags} />
            <Link to={post.fields.slug}>
              <ItemTitle marginTop="6px">{post.frontmatter.title}</ItemTitle>
              <div className="post-card-description">
                <p>{post.frontmatter.description}</p>
              </div>
            </Link>
          </div>
          <footer className="post-card-meta">
            <Block display="flex" flexDirection="row">
              <Avatar name={post.frontmatter.author} size="scale1000" />
              <Block display="flex" flexDirection="column">
                <Author>{post.frontmatter.author}</Author>
                <DateInfo>
                  {`${post.frontmatter.date} · ${post.timeToRead} min read`}
                </DateInfo>
              </Block>
            </Block>
          </footer>
        </div>
      </article>
    </PostCardSection>
  )
}
