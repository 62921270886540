import React from "react"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover"
import { Link } from "gatsby"
import { ItemDescription, ItemTitle } from "../typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

export default ({ content, children }) => {
  const styledContent = () => (
    <Block maxWidth="400px">
      {content.map(item => (
        <Container key={item.title}>
          <FlushedGrid>
            <Block
              paddingTop="20px"
              paddingBottom="20px"
              paddingLeft="20px"
              paddingRight="20px"
            >
              <Cell key={`menudropdown-${item.title}`} span={12}>
                <Link
                  id={`nav-${item.title.replace(/\s+/g, "-").toLowerCase()}`}
                  to={item.path}
                >
                  <ItemTitle font="LabelMedium">{item.title}</ItemTitle>
                  <ItemDescription font="ParagraphMedium">
                    {item.description}
                  </ItemDescription>
                </Link>
              </Cell>
            </Block>
          </FlushedGrid>
        </Container>
      ))}
    </Block>
  )
  return (
    <StatefulPopover
      autoFocus
      returnFocus
      renderAll
      triggerType={TRIGGER_TYPE.click}
      placement={PLACEMENT.bottom}
      content={styledContent}
      overrides={{
        Body: {
          style: () => ({
            zIndex: 2,
          }),
        },
      }}
    >
      {children}
    </StatefulPopover>
  )
}
