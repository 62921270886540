/* eslint-disable camelcase */
export const logLandingEvent = (event_category, event_label, interest) => {
  process.env.NODE_ENV !== "development" &&
    typeof window !== "undefined" &&
    window.gtag("event", "landing", {
      event_category,
      event_label,
      interest,
    })
}

export const logNewsletterSignUp = () => {
  process.env.NODE_ENV !== "development" &&
    typeof window !== "undefined" &&
    window.gtag("event", "sign_up", {
      method: "newsletter",
    })
}

export const logContentEvent = (event_category, event_label) => {
  process.env.NODE_ENV !== "development" &&
    typeof window !== "undefined" &&
    window.gtag("event", "content", {
      event_category,
      event_label,
    })
}
