import styled from "styled-components"
import FooterImage from "../../images/footer-bg.png"

const FooterWrapper = styled.footer`
  position: relative;
  background: #fff;
  overflow: hidden;
  padding: 15px 0 40px 0;

  .footer_container {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url(${FooterImage});
    }
    @media (max-width: 990px) {
      padding-bottom: 40px;
    }
    @media (max-width: 767px) {
      padding-bottom: 0px;
    }
  }
`

export default FooterWrapper
