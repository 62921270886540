import React, { useState, useEffect, useMemo } from "react"
import converter from "json-2-csv"
import { Block } from "baseui/block"
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic"

export const csv2json = async csv => {
  const json = converter
    .csv2jsonAsync(csv)
    .then()
    .catch(err => console.log(`ERROR: ${err.message}`))
  return json
}

const overrides = {
  Root: {
    style: {
      maxHeight: "600px",
    },
  },
}

const isNumeric = str => {
  if (typeof str != "string") return false // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ) // ...and ensure strings of whitespace fail
}

export const DataTable = ({ columns, data }) => {
  const [sortColumn, setSortColumn] = useState("bar")
  const [sortAsc, setSortAsc] = useState(true)
  const sortedData = useMemo(() => {
    return data.slice().sort((a, b) => {
      const left = sortAsc ? a : b
      const right = sortAsc ? b : a
      const leftValue = String(left[sortColumn])
      const rightValue = String(right[sortColumn])
      return leftValue.localeCompare(rightValue, "en", {
        numeric: true,
        sensitivity: "base",
      })
    })
  }, [sortColumn, sortAsc, data])

  function handleSort(id) {
    if (id === sortColumn) {
      setSortAsc(asc => !asc)
    } else {
      setSortColumn(id)
      setSortAsc(true)
    }
  }
  return (
    <TableBuilder
      data={sortedData}
      sortColumn={sortColumn}
      sortOrder={sortAsc ? "ASC" : "DESC"}
      onSort={handleSort}
      overrides={overrides}
    >
      {columns.map(col => (
        <TableBuilderColumn
          key={col.name}
          id={col.name}
          header={col.name}
          sortable={col.sortable}
          numeric={col.numeric}
          overrides={{
            TableHeadCellSortable: {
              style: {
                fontSize: "14px",
              },
            },
          }}
        >
          {row => <Block font="ParagraphSmall">{row[col.selector]}</Block>}
        </TableBuilderColumn>
      ))}
    </TableBuilder>
  )
}

export const CsvDataTable = ({ csv }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const json = await csv2json(csv)
      setData(json)
    }

    fetchData()
  }, [])

  const columns = data
    ? Object.keys(data[0]).map(key => {
        return {
          name: key,
          selector: key,
          sortable: true,
          numeric: isNumeric(key) ? true : false,
        }
      })
    : null

  return <>{data && <DataTable columns={columns} data={data} />}</>
}

export default DataTable
