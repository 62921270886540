import React from 'react';
import {Block} from 'baseui/block';

const Container = ({
  children,
  marginLeft="auto",
  marginRight="auto",
  ...rest
}) => {
  return (
    <Block
      marginLeft={marginLeft}
      marginRight={marginRight}
      paddingLeft={["40px", "40px", "0px"]}
      paddingRight={["40px", "40px", "0px"]}
      maxWidth={["100%", "100%", "900px", "1170px"]}
      {...rest}
    >
      {children}
    </Block>
  );
};

export default Container;
