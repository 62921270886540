import React, { useEffect } from "react"
import Prism from "prismjs"
import {
  DisplayMedium,
  HeadingLarge,
  LabelLarge,
  ParagraphLarge,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography"

/**
 * Landing Display
 */
export const Display = ({ children, textAlign = "left", ...rest }) => (
  <DisplayMedium
    font={["DisplayXSmall", "DisplaySmall", "DisplayMedium", "DisplayMedium"]}
    as="h1"
    marginBottom="20px"
    marginTop="0px"
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </DisplayMedium>
)

/**
 * Section description
 */
export const DisplayDescription = ({
  children,
  textAlign = "left",
  ...rest
}) => (
  <ParagraphLarge
    marginBottom="10px"
    marginTop="10px"
    font={[
      "ParagraphMedium",
      "ParagraphMedium",
      "ParagraphLarge",
      "ParagraphLarge",
    ]}
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </ParagraphLarge>
)

/**
 * Section Titles
 */
export const Title = ({ children, textAlign = "left", ...rest }) => (
  <HeadingLarge
    font={["HeadingLarge", "HeadingXLarge", "HeadingXLarge", "HeadingXXLarge"]}
    as="h2"
    marginBottom="18px"
    marginTop="18px"
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </HeadingLarge>
)

/**
 * Section Sub-titles
 */
export const SubTitle = ({ children, textAlign = "left", ...rest }) => (
  <LabelLarge
    font={["LabelMedium", "LabelLarge", "LabelLarge", "LabelLarge"]}
    as="h3"
    color="#525f7f"
    marginBottom="10px"
    marginTop="10px"
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </LabelLarge>
)

/**
 * Section description
 */
export const Description = ({ children, textAlign = "left", ...rest }) => (
  <ParagraphLarge
    font={[
      "ParagraphMedium",
      "ParagraphMedium",
      "ParagraphLarge",
      "ParagraphLarge",
    ]}
    marginBottom="10px"
    marginTop="10px"
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </ParagraphLarge>
)

/**
 * Item / feature title
 */
export const ItemTitle = ({ children, textAlign = "left", ...rest }) => (
  <LabelLarge
    font={["LabelMedium", "LabelLarge", "LabelLarge", "LabelLarge"]}
    as="h3"
    marginBottom="10px"
    marginTop="0"
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </LabelLarge>
)

/**
 * Item / feature description
 */
export const ItemDescription = ({ children, textAlign = "left", ...rest }) => (
  <ParagraphMedium
    font={[
      "ParagraphSmall",
      "ParagraphMedium",
      "ParagraphMedium",
      "ParagraphMedium",
    ]}
    marginBottom="0"
    marginTop="0"
    color="#525f7f"
    {...rest}
    overrides={{
      Block: {
        style: {
          textAlign,
        },
      },
    }}
  >
    {children}
  </ParagraphMedium>
)

export const Code = ({ children, language }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <pre>
      <code className={`language-${language}`}>{children}</code>
    </pre>
  )
}
