// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blocklynx-signup-js": () => import("./../../../src/pages/blocklynx-signup.js" /* webpackChunkName: "component---src-pages-blocklynx-signup-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-content-fatf-red-flags-js": () => import("./../../../src/pages/content/fatf-red-flags.js" /* webpackChunkName: "component---src-pages-content-fatf-red-flags-js" */),
  "component---src-pages-content-index-js": () => import("./../../../src/pages/content/index.js" /* webpackChunkName: "component---src-pages-content-index-js" */),
  "component---src-pages-crypto-news-api-js": () => import("./../../../src/pages/crypto-news-api.js" /* webpackChunkName: "component---src-pages-crypto-news-api-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-motif-js": () => import("./../../../src/pages/motif.js" /* webpackChunkName: "component---src-pages-motif-js" */),
  "component---src-pages-motif-signup-js": () => import("./../../../src/pages/motif-signup.js" /* webpackChunkName: "component---src-pages-motif-signup-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-transaction-monitoring-js": () => import("./../../../src/pages/transaction-monitoring.js" /* webpackChunkName: "component---src-pages-transaction-monitoring-js" */),
  "component---src-pages-use-cases-blockchain-forensics-js": () => import("./../../../src/pages/use-cases/blockchain-forensics.js" /* webpackChunkName: "component---src-pages-use-cases-blockchain-forensics-js" */),
  "component---src-pages-use-cases-fraud-detection-js": () => import("./../../../src/pages/use-cases/fraud-detection.js" /* webpackChunkName: "component---src-pages-use-cases-fraud-detection-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

