import styled from "styled-components"

export const NewsletterWrapper = styled.div`
  position: relative;
  padding: 44px 60px;
  border-top: 8px solid teal;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -105px;
  margin-bottom: 50px;
  @media (max-width: 1220px) {
    padding: 35px 40px;
  }
  @media (max-width: 575px) {
    padding: 35px 20px;
  }
  @media (max-width: 990px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const NewsletterWrapperBlog = styled.div`
  position: relative;
  padding: 30px 60px;
  border-top: 8px solid teal;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);
  border-radius: 5px;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 1220px) {
    padding: 35px 40px;
  }
  @media (max-width: 575px) {
    padding: 35px 20px;
  }
`

export const ContactFormWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 575px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }
`
