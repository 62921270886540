/* eslint-disable no-unused-expressions */
import React from "react"
import CookieConsent from "react-cookie-consent"

export default () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      enableDeclineButton
      flipButtons
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
      buttonStyle={{
        background: "#0576d3",
        color: "white",
        fontWeight: "bolder",
      }}
      declineButtonStyle={{
        background: "light gray",
        color: "gray",
        fontWeight: "bolder",
      }}
    >
      <a
        to="https://www.cookiesandyou.com/"
        style={{ color: "white" }}
        target="_blank"
      >
        This website uses cookies to ensure you get the best experience.{" "}
        <u> Learn more.</u>
      </a>
    </CookieConsent>
  )
}
