import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body{
    font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #102A43;
  }

  h1, h2 {
    font-weight: 700;
  }

  h3, h4 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: "#1992D4";
  }

  a:hover {
    text-decoration: none;
    color: "#1992D4";
  }

  a:visited {
    text-decoration: none;
    color: "#1992D4";
  }
  

  section {
    position: relative;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

/* Post content styling */

.post-content ul,
.post-content ol,
.post-content p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin-top: 32px;
  color: #102A43;
}

.post-content table {
  border-collapse: collapse; 
  overflow-x: auto;
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  overflow-x: auto;
  white-space: nowrap;
}
.post-content td {
  border: 1px solid #ddd;
  padding-left: 8px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.post-content tr:nth-child(even){background-color: #f2f2f2;}
.post-content th {
  text-align: left;
  border: 1px solid #ddd;
  background-color: #16806f;
  color: #f2f2f2;
  padding-left: 8px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  :hover {
    background-color: #16806f;
  }
}
@media (max-width: 500px) {
  .post-content ul,
  .post-content ol,
  .post-content p {
      font-size: 18px;
      line-height: 28px;
  }
}

.post-content ul p,
.post-content ol p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.post-content {
  display: flex;
  flex-direction: column;
}

.post-content img {
  width: 100%;
  height: 100%;
}

.post-content-image img {
  max-width: 1000px;
  height: auto;
}
@media (max-width: 1000px) {
  .post-content img {
    max-width: calc(100vw - 60px);
  }
}

.post-content h1::before,
.post-content h2::before,
.post-content h3::before,
.post-content h4::before { 
  display: block; 
  content: " "; 
  margin-top: -180px; 
  height: 180px; 
  visibility: hidden; 
  pointer-events: none;
}

.post-content h1 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    color: #102A43;
}
@media (max-width: 500px) {
    .post-content h1 {
        font-size: 30px;
        line-height: 40px;
    }
}

.post-content h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: #102A43;
}
@media (max-width: 500px) {
    .post-content h2 {
      font-size: 24px;
      line-height: 30px;
    }
}

.post-content h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: #102A43;
}
@media (max-width: 500px) {
    .post-content h3 {
      font-size: 18px;
      line-height: 26px;
    }
}

.post-content h4 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: #627D98;
}
@media (max-width: 500px) {
    .post-content h4 {
      font-size: 18px;
      line-height: 28px;
    }
}

.post-content blockquote{
  font-size: 20px;
  line-height: 32px;
  font-style:italic;
  padding-left: 23px;
  border-left:4px solid #102A43;
}
@media (max-width: 500px) {
  .post-content blockquote {
    font-size: 18px;
    line-height: 28px;
  }
}

.post-content pre {
  padding: 16px;
  overflow: auto;
  font-size: 14px;
  line-height: 18px;
  background-color: #f6f8fa;
  border-radius: 3px;
}

/* Post content header styling */
.post-divider {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #D9E2EC;
  padding-top: 16px;
  margin-top: 16px;
}


.drawer-content-wrapper{
  @media (max-width: 767px) {
    width: 300px!important;
  }
  .drawer-content {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 767px) {
      padding: 50px 40px 30px 40px;
    }
    .mobile_menu {
      margin-bottom: 40px;
      flex-grow: 1;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      li{
        list-style: none;
        margin-bottom: 35px;
        @media (max-width: 767px) {
          margin-bottom: 25px;
        }
        div, a{
          font-size: 20px;
          position: relative;
          transition: 0.15s ease-in-out;
          @media (max-width: 767px) {
            font-size: 18px;
          }
          &:hover {
            color: rgb(255,148,147);
          }
          &:before{
            content: '';
            width: 7px;
            height: 7px;
            background: rgb(255,148,147);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
            opacity: 0;
          }
        }
        &.is-current {
          a {
            color: rgb(255,148,147);;
            &:before{
              opacity: 1;
            }
          }
        }
      }
    }
    .navbar_drawer_button button{
      width: 100%;
    }
  }

  .reusecore-drawer__close{
    width: 34px;
    height: 34px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 767px) {
      top: 15px;
      right: 15px;
    }
    &:before{
      content: '\f10b';
      font-family: Flaticon;
      font-size: 26px;
      color: #2aa275;
      transform: rotate(45deg);
      display: block;
    }
  }
}
`

export const ContentWrapper = styled.div`
  overflow: hidden;
  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a,
          div {
            color: #000;
            &:hover {
              color: rgb(255, 148, 147);
            }
          }
          &.is-current {
            a {
              color: rgb(255, 148, 147);
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #2aa275;
      }
    }
  }

  .saas_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: rgb(255, 148, 147);
          }
        }
        a,
        div {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: 0.15s ease-in-out;
          cursor: pointer;
          &:hover {
            color: rgb(255, 148, 147);
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
`
