// Adapated from https://github.com/styxlab/gatsby-theme-try-ghost/blob/master/packages/gatsby-theme-try-ghost/src/styles/screen-original.css
import styled from "styled-components"

const PostCardSection = styled.section`
  .post-card {
    position: relative;
    flex: 1 1 301px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 0 40px;
    padding: 0 20px 40px;
    min-height: 220px;
    border-bottom: 1px solid color(var(--lightgrey) l(+12%));
    background-size: cover;
  }

  .post-card-description p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #486581;
    margin-top: 0;
    font-size: 18px;
    line-height: 28px;
    max-height: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 3px;
  }

  .post-card-image {
    max-height: 200px;
    width: 100%;
    background: var(--lightgrey) no-repeat center center;
    object-fit: cover;
  }

  @media (min-width: 500px) {
    .post-card-image {
      max-height: 300px;
    }
  }

  .post-card-content-link {
    position: relative;
    display: block;
    color: var(--darkgrey);
  }

  .post-card-content-link:hover {
    text-decoration: none;
  }

  .post-card-header {
    margin: 15px 0 0;
  }

  .post-feed .no-image .post-card-content-link {
    padding: 0;
  }

  .no-image .post-card-header {
    margin-top: 0;
  }

  .post-card-primary-tag {
    margin: 0 0 0.2em;
    color: "#1992D4";
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .post-card-title {
    margin: 0 0 0.4em;
    line-height: 1.15em;
    transition: color 0.2s ease-in-out;
  }

  .no-image .post-card-title {
    margin-top: 0;
  }

  .post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .post-card-excerpt {
    max-width: 56em;
    color: var(--midgrey);
    font-family: Georgia, serif;
  }

  .post-card-excerpt p {
    margin-bottom: 1em;
  }

  .post-card-meta {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }

  /* Special Styling:
The first post in the list is styled to be bigger than the others and take over
the full width of the grid to give it more emphasis. Wrapped in a media query to
make sure this only happens on large viewports / desktop-ish devices.
 */

  @media (min-width: 900px) {
    .post-feed,
    .post-feed,
    .post-feed {
      padding: 40px 0 5vw;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large:hover {
      border-bottom-color: color(var(--lightgrey) l(+10%));
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      height: 400px;
    }

    .post-card-large .post-card-image {
      position: absolute;
      height: 400px;
      max-height: 400px;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }

  /* Adjust some margins for smaller screens */
  @media (max-width: 1170px) {
    .post-card {
      margin-bottom: 5vw;
    }
  }

  @media (max-width: 650px) {
    .post-feed {
      padding-top: 5vw;
    }

    .post-card {
      margin-bottom: 5vw;
    }
  }

  @media (max-width: 500px) {
    .post-card-title {
      font-size: 1.9rem;
    }

    .post-card-excerpt {
      font-size: 1.6rem;
    }
  }
`

const FeatureSection = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .featureWrapper {
    max-width: 400px;
    margin-bottom: 45px;
    display: flex;

    @media (max-width: 599px) {
      max-width: 100%;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-bottom: 20px;
      border-width: 2px;
      border-color: rgb(233, 227, 254);
      border-radius: 10px;
      background-color: rgb(243, 242, 250);
      padding: 8px 10px;
    }
    .contextPortion {
      margin-left: 15px;
    }
  }
`
export { PostCardSection, FeatureSection }
